// tslint:disable
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';


/**
* MFIDeliveryScheduleParameters entity interface.
*/
export interface IMFIDeliveryScheduleParameters {
  ZipCode?: string;
  RequestedDate?: string;
  StoreId?: string;
  InventoryType?: string;
  Weeks: number;
  NoOfPriorityDC: number;
  Page?: string;
  SourceSystem?: string;
  CustomerRequestTime?: string;
  Id: number;
  ExtensionProperties?: Entities.CommerceProperty[];
  ItemLines?: IMFICartLine[];
}

/**
* MFICartLine entity interface.
*/
export interface IMFICartLine {
  ItemId?: string;
  VariantRecordId?: string;
  ProductId?: string;
  Quantity: number;
  QuantityOrdered?: number;
  IsVoided?: boolean;
  Id?: number;
  ExtensionProperties?: Entities.CommerceProperty[];
}

/**
* MFIATPInventoryEntity entity interface.
*/
export interface IMFIATPInventoryEntity {
  Location1?: string;
  Location2?: string;
  Zone?: string;
  ZoneId?: string;
  SlotDate?: string;
  StartTime?: string;
  EndTime?: string;
  Slots?: string;
  MFIATPLeadDate?: string;
  Available?: string;
  ATPQuantity?: string;
  SourceSystem?: string;
  IsTranscity?: boolean;
  IsNationWide?: boolean;
}

/**
* MFIATPInventoryEntityPLP entity interface.
*/
export interface IMFIATPInventoryEntityPLP {
  Location1?: string;
  ItemId?: string;
  Variant?: string;
  VariantRecordId?: string;
  Zone?: string;
  ZoneId?: string;
  SlotDate?: string;
  StartTime?: string;
  EndTime?: string;
  Slots?: string;
  MFIATPLeadDate?: string;
  Available?: string;
  ATPQuantity?: string;
  SourceSystem?: string;
  IsTranscity?: boolean;
  IsNationWide?: boolean;
  ZipCodeGroupId?: string;
  ZoneLineId?: string;
}

/**
* MFIATPInventoryDynamicEntity entity interface.
*/
export interface IMFIATPInventoryDynamicEntity {
  Date?: string;
  AvailableSlots?: string;
  ATPSlots?: IMFIATPInventoryEntity[];
}

/**
* MFIATPInventoryStatusEntity entity interface.
*/
export interface IMFIATPInventoryStatusEntity {
  Location?: string;
  ProductId?: string;
  ItemId?: string;
  VariantRecordId?: string;
  Date?: string;
  Available?: string;
  Quantity?: string;
  SourceSystem?: string;
}

/**
* MFIATPInventoryResponse entity interface.
*/
export interface IMFIATPInventoryResponse {
  ATPInventoryData?: IMFIATPInventoryEntity[];
  Message?: string;
}

/**
* MFIATPInventoryPLPResponse entity interface.
*/
export interface IMFIATPInventoryPLPResponse {
  ATPInventoryPLPData?: IMFIATPInventoryEntityPLP[];
  Message?: string;
}

/**
* MFIATPInventoryDynamicResponse entity interface.
*/
export interface IMFIATPInventoryDynamicResponse {
  ATPInventoryDynamicData?: IMFIATPInventoryDynamicEntity[];
  Message?: string;
}

/**
* MFIATPInventoryStatusResponse entity interface.
*/
export interface IMFIATPInventoryStatusResponse {
  ATPInventoryStatusData?: IMFIATPInventoryStatusEntity[];
  Message?: string;
}

/**
 * MFIDeliveryScheduleParameters entity class.
 */
export class MFIDeliveryScheduleParametersExtensionClass implements IMFIDeliveryScheduleParameters {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ZipCode: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public RequestedDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public StoreId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public InventoryType: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Weeks: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public NoOfPriorityDC: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Page: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SourceSystem: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CustomerRequestTime: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Id: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: Entities.CommerceProperty[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemLines:
    IMFICartLine[];

  // Navigation properties names

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public static itemLinesPropertyName: string = "ItemLines";

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ZipCode = odataObject.ZipCode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.RequestedDate = odataObject.RequestedDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.StoreId = odataObject.StoreId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.InventoryType = odataObject.InventoryType;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Weeks = odataObject.Weeks;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.NoOfPriorityDC = odataObject.NoOfPriorityDC;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Page = odataObject.Page;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SourceSystem = odataObject.SourceSystem;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CustomerRequestTime = odataObject.CustomerRequestTime;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Id = odataObject.Id;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ExtensionProperties = undefined;
    if (odataObject.ExtensionProperties) {
      this.ExtensionProperties = [];
      for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i]) {
          if (odataObject.ExtensionProperties[i]['@odata.type']) {
            var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
            }
          } else {
            this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ExtensionProperties[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemLines = undefined;
    if (odataObject.ItemLines) {
      this.ItemLines = [];
      for (var i = 0; i < odataObject.ItemLines.length; i++) {
        if (odataObject.ItemLines[i]) {
          if (odataObject.ItemLines[i]['@odata.type']) {
            var className: string = odataObject.ItemLines[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ItemLines[i] = new EntityClasses[className](odataObject.ItemLines[i]);
            }
          } else {
            this.ItemLines[i] = new MFICartLineExtensionClass(odataObject.ItemLines[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ItemLines[i] = undefined;
        }
      }
    }

  }
}

/**
 * MFICartLine entity class.
 */
export class MFICartLineExtensionClass implements IMFICartLine {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public VariantRecordId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ProductId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Quantity: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public QuantityOrdered: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsVoided: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Id: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: Entities.CommerceProperty[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemId = odataObject.ItemId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.VariantRecordId = odataObject.VariantRecordId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ProductId = odataObject.ProductId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.QuantityOrdered = (odataObject.QuantityOrdered) ? parseFloat(odataObject.QuantityOrdered) : 0;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsVoided = odataObject.IsVoided;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Id = odataObject.Id;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ExtensionProperties = undefined;
    if (odataObject.ExtensionProperties) {
      this.ExtensionProperties = [];
      for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i]) {
          if (odataObject.ExtensionProperties[i]['@odata.type']) {
            var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
            }
          } else {
            this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ExtensionProperties[i] = undefined;
        }
      }
    }

  }
}

/**
 * MFIATPInventoryEntity entity class.
 */
export class MFIATPInventoryEntityExtensionClass implements IMFIATPInventoryEntity {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Location1: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Location2: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Zone: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ZoneId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SlotDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public StartTime: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public EndTime: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Slots: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public MFIATPLeadDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Available: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ATPQuantity: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SourceSystem: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsTranscity: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsNationWide: boolean;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Location1 = odataObject.Location1;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Location2 = odataObject.Location2;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Zone = odataObject.Zone;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ZoneId = odataObject.ZoneId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SlotDate = odataObject.SlotDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.StartTime = odataObject.StartTime;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.EndTime = odataObject.EndTime;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Slots = odataObject.Slots;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.MFIATPLeadDate = odataObject.MFIATPLeadDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Available = odataObject.Available;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ATPQuantity = odataObject.ATPQuantity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SourceSystem = odataObject.SourceSystem;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsTranscity = odataObject.IsTranscity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsNationWide = odataObject.IsNationWide;

  }
}

/**
 * MFIATPInventoryEntityPLP entity class.
 */
export class MFIATPInventoryEntityPLPExtensionClass implements IMFIATPInventoryEntityPLP {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Location1: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Variant: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public VariantRecordId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Zone: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ZoneId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SlotDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public StartTime: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public EndTime: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Slots: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public MFIATPLeadDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Available: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ATPQuantity: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SourceSystem: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsTranscity: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsNationWide: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ZipCodeGroupId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ZoneLineId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Location1 = odataObject.Location1;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemId = odataObject.ItemId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Variant = odataObject.Variant;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.VariantRecordId = odataObject.VariantRecordId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Zone = odataObject.Zone;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ZoneId = odataObject.ZoneId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SlotDate = odataObject.SlotDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.StartTime = odataObject.StartTime;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.EndTime = odataObject.EndTime;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Slots = odataObject.Slots;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.MFIATPLeadDate = odataObject.MFIATPLeadDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Available = odataObject.Available;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ATPQuantity = odataObject.ATPQuantity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SourceSystem = odataObject.SourceSystem;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsTranscity = odataObject.IsTranscity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsNationWide = odataObject.IsNationWide;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ZipCodeGroupId = odataObject.ZipCodeGroupId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ZoneLineId = odataObject.ZoneLineId;

  }
}

/**
 * MFIATPInventoryDynamicEntity entity class.
 */
export class MFIATPInventoryDynamicEntityExtensionClass implements IMFIATPInventoryDynamicEntity {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Date: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AvailableSlots: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ATPSlots:
    IMFIATPInventoryEntity[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Date = odataObject.Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AvailableSlots = odataObject.AvailableSlots;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ATPSlots = undefined;
    if (odataObject.ATPSlots) {
      this.ATPSlots = [];
      for (var i = 0; i < odataObject.ATPSlots.length; i++) {
        if (odataObject.ATPSlots[i]) {
          if (odataObject.ATPSlots[i]['@odata.type']) {
            var className: string = odataObject.ATPSlots[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ATPSlots[i] = new EntityClasses[className](odataObject.ATPSlots[i]);
            }
          } else {
            this.ATPSlots[i] = new MFIATPInventoryEntityExtensionClass(odataObject.ATPSlots[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ATPSlots[i] = undefined;
        }
      }
    }

  }
}

/**
 * MFIATPInventoryStatusEntity entity class.
 */
export class MFIATPInventoryStatusEntityExtensionClass implements IMFIATPInventoryStatusEntity {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Location: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ProductId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public VariantRecordId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Date: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Available: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Quantity: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SourceSystem: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Location = odataObject.Location;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ProductId = odataObject.ProductId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemId = odataObject.ItemId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.VariantRecordId = odataObject.VariantRecordId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Date = odataObject.Date;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Available = odataObject.Available;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Quantity = odataObject.Quantity;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SourceSystem = odataObject.SourceSystem;

  }
}

/**
 * MFIATPInventoryResponse entity class.
 */
export class MFIATPInventoryResponseExtensionClass implements IMFIATPInventoryResponse {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ATPInventoryData:
    IMFIATPInventoryEntity[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ATPInventoryData = undefined;
    if (odataObject.ATPInventoryData) {
      this.ATPInventoryData = [];
      for (var i = 0; i < odataObject.ATPInventoryData.length; i++) {
        if (odataObject.ATPInventoryData[i]) {
          if (odataObject.ATPInventoryData[i]['@odata.type']) {
            var className: string = odataObject.ATPInventoryData[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ATPInventoryData[i] = new EntityClasses[className](odataObject.ATPInventoryData[i]);
            }
          } else {
            this.ATPInventoryData[i] = new MFIATPInventoryEntityExtensionClass(odataObject.ATPInventoryData[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ATPInventoryData[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;

  }
}

/**
 * MFIATPInventoryPLPResponse entity class.
 */
export class MFIATPInventoryPLPResponseExtensionClass implements IMFIATPInventoryPLPResponse {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ATPInventoryPLPData:
    IMFIATPInventoryEntityPLP[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ATPInventoryPLPData = undefined;
    if (odataObject.ATPInventoryPLPData) {
      this.ATPInventoryPLPData = [];
      for (var i = 0; i < odataObject.ATPInventoryPLPData.length; i++) {
        if (odataObject.ATPInventoryPLPData[i]) {
          if (odataObject.ATPInventoryPLPData[i]['@odata.type']) {
            var className: string = odataObject.ATPInventoryPLPData[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ATPInventoryPLPData[i] = new EntityClasses[className](odataObject.ATPInventoryPLPData[i]);
            }
          } else {
            this.ATPInventoryPLPData[i] = new MFIATPInventoryEntityPLPExtensionClass(odataObject.ATPInventoryPLPData[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ATPInventoryPLPData[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;

  }
}

/**
 * MFIATPInventoryDynamicResponse entity class.
 */
export class MFIATPInventoryDynamicResponseExtensionClass implements IMFIATPInventoryDynamicResponse {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ATPInventoryDynamicData:
    IMFIATPInventoryDynamicEntity[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ATPInventoryDynamicData = undefined;
    if (odataObject.ATPInventoryDynamicData) {
      this.ATPInventoryDynamicData = [];
      for (var i = 0; i < odataObject.ATPInventoryDynamicData.length; i++) {
        if (odataObject.ATPInventoryDynamicData[i]) {
          if (odataObject.ATPInventoryDynamicData[i]['@odata.type']) {
            var className: string = odataObject.ATPInventoryDynamicData[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ATPInventoryDynamicData[i] = new EntityClasses[className](odataObject.ATPInventoryDynamicData[i]);
            }
          } else {
            this.ATPInventoryDynamicData[i] = new MFIATPInventoryDynamicEntityExtensionClass(odataObject.ATPInventoryDynamicData[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ATPInventoryDynamicData[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;

  }
}

/**
 * MFIATPInventoryStatusResponse entity class.
 */
export class MFIATPInventoryStatusResponseExtensionClass implements IMFIATPInventoryStatusResponse {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ATPInventoryStatusData:
    IMFIATPInventoryStatusEntity[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ATPInventoryStatusData = undefined;
    if (odataObject.ATPInventoryStatusData) {
      this.ATPInventoryStatusData = [];
      for (var i = 0; i < odataObject.ATPInventoryStatusData.length; i++) {
        if (odataObject.ATPInventoryStatusData[i]) {
          if (odataObject.ATPInventoryStatusData[i]['@odata.type']) {
            var className: string = odataObject.ATPInventoryStatusData[i]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[className]) {
              this.ATPInventoryStatusData[i] = new EntityClasses[className](odataObject.ATPInventoryStatusData[i]);
            }
          } else {
            this.ATPInventoryStatusData[i] = new MFIATPInventoryStatusEntityExtensionClass(odataObject.ATPInventoryStatusData[i]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ATPInventoryStatusData[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;

  }
}
