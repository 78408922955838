import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { IMFIATPInventoryEntity, IMFICartLine, IMFICLDeliveryScheduleParameters } from '../actions/CoreProductDataServiceEntities.g';
import { IMFIAvailablePromotionDescription, IMFIItem } from '../actions/EyebrowMessageDataServiceEntities.g';
import getEyebrowAction, { GetEyebrowMessageInput } from '../actions/get-eyebrow-message.action';
import _getDeliveryByMessage from './get-delivery-by-message';
import { IMFIATPInventoryEntityPLP } from '../actions/BatchCoreProductDataServiceEntities.g';

export const _getEyebrowMessage = async (products: IMFIItem[], context: ICoreContext<IGeneric<IAny>>) => {
    const priceGroupForAPICall = context.app && context.app.config.priceGroup;
    if (products && products.length > 0 && (priceGroupForAPICall !== '' || priceGroupForAPICall !== undefined)) {
        const items: IMFIItem[] | undefined = [];
        products.map(item => {
            const singleItem: IMFIItem = {
                ItemId: item.ItemId,
                DistinctProductVariant: item.DistinctProductVariant
            };
            items.push(singleItem);
        });

        const requestBody: IMFIAvailablePromotionDescription = {
            PriceGroup: priceGroupForAPICall,
            Items: items
        };
        const getEyebrowMessageInput = new GetEyebrowMessageInput(requestBody, undefined, true);
        try {
            const result = await getEyebrowAction(getEyebrowMessageInput, context.actionContext);
            if (result) {
                return result;
            }
        } catch (error) {
            console.log('Error', error);
        }
    }
    return null;
};

export const _makeDeliveryMessage = (days: string) => {
    if (days === '' || days === undefined || days === null) {
        return '';
    }

    let deliveryByMessage = '';
    let date = new Date();
    const daysSpan = days.split('-');
    let deliveryDate = Date.now();
    if (daysSpan && daysSpan[1] !== undefined && daysSpan.length) {
        let deliveryByMessageRange = '';
        daysSpan.map((item: string) => {
            date = new Date();
            deliveryDate = Date.now();
            if (parseInt(item, 10) > 0) {
                let numberOfDays = parseInt(item, 10);
                let newDate = deliveryDate;
                while (numberOfDays > 0) {
                    newDate = date.setDate(date.getDate() + 1);
                    if (dayjs(newDate).format('d') !== '0' && dayjs(newDate).format('d') !== '6') {
                        numberOfDays--;
                    }
                }
                deliveryByMessage = dayjs(newDate).format('MMM D');
                if (deliveryByMessageRange !== '') {
                    deliveryByMessageRange = `${deliveryByMessageRange} to ${deliveryByMessage}`;
                } else {
                    deliveryByMessageRange = deliveryByMessage;
                    newDate = date.setDate(date.getDate() - parseInt(item[0], 10));
                }
            }
        });
        return `Delivery by ${deliveryByMessageRange}`;
    } else {
        deliveryByMessage = dayjs(deliveryDate).format('MMM D');
        let numberOfDays = parseInt(days, 10);
        let newDate = deliveryDate;
        let flag = true;
        while (numberOfDays > 0) {
            newDate = date.setDate(date.getDate() + 1);
            if (dayjs(newDate).format('d') !== '0' && dayjs(newDate).format('d') !== '6') {
                numberOfDays--;
            } else {
                flag = false;
            }
        }
        deliveryByMessage =
            flag && days === '1'
                ? `Tomorrow ${dayjs(Date.now()).format('MMM D')}`
                : dayjs(newDate).format('ddd MMM, D');
        return `Delivery by ${deliveryByMessage}`;
    }
};

// tslint:disable-next-line: no-any
export const _coreProductATPCall = async (
    itemLines: IMFICartLine[],
    setCoreProductATPData: React.Dispatch<any>,
    context: ICoreContext<IGeneric<IAny>>
) => {
    const cookies = new Cookies();
    const zipcode = cookies.get('zip_code');
    if (itemLines && itemLines.length === 0) {
        setCoreProductATPData([]);
    }
    const currentDate = Date.now();
    const requestBody: IMFICLDeliveryScheduleParameters = {
        InventoryType: 'Delivery',
        Weeks: context.app.config.weeksPDPforATPCall,
        StoreId: '',
        Page: 'plp',
        RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
        ZipCode: zipcode,
        ItemLines: itemLines
    };
    const getDeliveryByMessage = _getDeliveryByMessage(requestBody, context);
    getDeliveryByMessage
        .then(result => {
            if (result !== undefined && result?.ATPInventoryData && result?.ATPInventoryData.length > 0) {
                setCoreProductATPData(result?.ATPInventoryData);
            } else {
                setCoreProductATPData([]);
            }
        })
        .catch(e => {
            console.log('Error', e);
        });
};

const _setTime = (time: string | undefined) => {
    if (time !== undefined && time !== '') {
        const temp = time.split(' ');
        if (temp && temp.length > 0) {
            let newDatewithTime = dayjs(Date.now()).format('M/D/YYYY');
            newDatewithTime = `${newDatewithTime} ${temp[0]}:00 ${temp[1].toUpperCase()}`;
            return newDatewithTime;
        }
    }
    return '';
};

export interface IATPInventoryData {
    deliveryByMessage: string;
    locationId: string;
}

export const _getSoonestDeliveryDate = (coreProductATPData: IMFIATPInventoryEntity[]): IATPInventoryData => {
    let deliveryDetails = {
        deliveryByMessage: '',
        locationId: ''
    };
    if (!coreProductATPData || !coreProductATPData.length) {
        return deliveryDetails;
    }
    for (let index = 0; index < coreProductATPData.length; index++) {
        const item = coreProductATPData[index];
        if (item.Available === 'YES' && item.ATPQuantity !== undefined && parseInt(item.ATPQuantity, 10) > 0) {
            const date = new Date();
            const today = dayjs(Date.now()).format('D M YY');
            const deliveryDate = dayjs(item.SlotDate).format('D M YY');
            const currHour = dayjs(Date.now()).format('H');
            if (today === deliveryDate) {
                const deliveryMessage =
                    _setTime(item.StartTime) !== ''
                        ? parseInt(dayjs(_setTime(item.StartTime)).format('H'), 10) < 14 && parseInt(currHour, 10) < 14
                            ? `Delivery by Today ${dayjs(Date.now()).format('MMM D')}`
                            : `Delivery by Tomorrow ${dayjs(date.setDate(date.getDate() + 1)).format('MMM D')}`
                        : '';
                deliveryDetails = {
                    deliveryByMessage: deliveryMessage,
                    locationId: item.Location1!
                };
                break;
            } else {
                const deliveryMessage = `Delivery by ${dayjs(item.SlotDate).format('MMM D')}`;
                deliveryDetails = {
                    deliveryByMessage: deliveryMessage,
                    locationId: item.Location1!
                };
                break;
            }
        }
    }
    return deliveryDetails;
};

export const _getDeliveryDate = (coreProductATPData: IMFIATPInventoryEntityPLP) => {
    let deliveryDetails = {
        deliveryByMessage: '',
        locationId: ''
    };
    const item = coreProductATPData;
    if (item.Available === 'YES' && item.ATPQuantity !== undefined && parseInt(item.ATPQuantity, 10) > 0) {
        const date = new Date();
        const today = dayjs(Date.now()).format('D M YY');
        const deliveryDate = dayjs(item.SlotDate).format('D M YY');
        const currHour = dayjs(Date.now()).format('H');
        const startTimeHour = item.StartTime?.substring(0,2) || "14";
        if (today === deliveryDate) {
            const deliveryMessage =
                parseInt(startTimeHour, 10) < 14 && parseInt(currHour, 10) < 14
                    ? `Delivery by Today ${dayjs(Date.now()).format('MMM D')}`
                    : `Delivery by Tomorrow ${dayjs(date.setDate(date.getDate() + 1)).format('MMM D')}`;
            deliveryDetails = {
                deliveryByMessage: deliveryMessage,
                locationId: item.Location1!
            };
        } else {
            const deliveryMessage = `Delivery by ${dayjs(item.SlotDate).format('MMM D')}`;
            deliveryDetails = {
                deliveryByMessage: deliveryMessage,
                locationId: item.Location1!
            };
        }
    }
    return deliveryDetails;
};

